import { Div, ImageView, Label } from "components";
import { Ecolors } from "constants/themes";
import React from "react";
import { RefreshControl } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import {
  ItemOrderBuy,
  ItemOrderSell,
  ItemOrderTransfer,
} from "./ItemListTransaction";
import ItemListHistoryOrder from "./ItemListHistoryOrder";
import ItemAutoinvestTransaction from "./ItemAutoinvestTransaction";
import { Icons } from "constants/Icons";

const ListTransaction = (p: {
  data: Array<any>;
  refreshing: boolean;
  totalItemCount?: number;
  onLoadMore: () => void;
  onRefresh: () => void;
  itemRender:
    | "BUY"
    | "SELL"
    | "TRANSFER_ALL"
    | "AutoInvestTransaction"
    | "HistoryTransaction";
}) => {
  const renderItem = (propsItem: { item: any; index: number }) => {
    switch (p.itemRender) {
      case "BUY":
        return <ItemOrderBuy data={propsItem.item} />;
      case "SELL":
        return <ItemOrderSell data={propsItem.item} />;
      case "TRANSFER_ALL":
        return <ItemOrderTransfer data={propsItem.item} />;
      case "AutoInvestTransaction":
        return <ItemAutoinvestTransaction data={propsItem.item} />;
      case "HistoryTransaction":
        return <ItemListHistoryOrder data={propsItem.item} />;
      default:
        return <Div />;
    }
  };

  const onRefresh = () => {
    p.onRefresh();
  };

  const onLoadMore = () => {
    p.onLoadMore();
  };

  const ItemSeparatorComponent = () => {
    return <Div height={10} />;
  };

  const ListFooterComponent = () => {
    return <Div height={10} />;
  };

  const ListEmptyComponent = () => {
    if (p.refreshing) {
      return <Div />;
    }
    return (
      <Div paddingTop={180} alignItems="center" justifyContent="center">
        <ImageView
          source={Icons.nonassets}
          width={161}
          height={130}
          resizeMode="contain"
        />
        <Label
          marginTop={28}
          fontWeight="bold"
        >{`overviewscreen.banchuacotaisan`}</Label>
      </Div>
    );
  };

  const ListHeaderComponent = () => {
    if (!p?.totalItemCount) return <Div height={10} />;
    return (
      <Div
        flexDirection="row"
        padding={15}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Label>{`transactionscreen.tongsolenh`}</Label>
        <Label multilanguage={false}>{` ${p?.totalItemCount}`}</Label>
      </Div>
    );
  };

  return (
    <FlatList
      data={p.data}
      extraData={[p.data]}
      renderItem={renderItem}
      keyExtractor={(item, index) => `key${item.id}${index}`}
      refreshControl={
        <RefreshControl
          refreshing={p.refreshing}
          onRefresh={onRefresh}
          tintColor={Ecolors.mainColor}
        />
      }
      ListEmptyComponent={ListEmptyComponent}
      ItemSeparatorComponent={ItemSeparatorComponent}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListFooterComponent}
      onEndReachedThreshold={0.4}
      onEndReached={onLoadMore}
      keyboardShouldPersistTaps={"handled"}
    />
  );
};

export default React.memo(ListTransaction);
