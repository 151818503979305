import { Alert, Div } from "components";
import { Ecolors } from "constants/themes";
import React, { useEffect, useRef, useState } from "react";
import { ScrollView } from "react-native";
import { apiInvestment } from "services/apiInvestment";
import { useAppSelector } from "store/hooks";
import { Log, widthScreen } from "utils";
import OrderBuyStep1 from "./OrderBuyStep1";
import OrderBuyStep2 from "./OrderBuyStep2";
import OrderBuyStep3 from "./OrderBuyStep3";

interface Props {
  setStepTimeLine: (a: number) => void;
  stepTimeLine?: number;
  initData?: any;
}

function OrderBuy({ setStepTimeLine, stepTimeLine, initData }: Props) {
  // state order buy
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  const [product, setProduct] = useState<any>(null);
  // const listProduct = useAppSelector((state) => getListProduct(state));
  const [listProduct, setListProduct] = useState<Array<any>>([]);
  const [scheme, setScheme] = useState<any>(null);
  const [listScheme, setListScheme] = useState<Array<any>>([]);
  const [amount, setAmount] = useState<string>("");
  const [beginBuyAutoStartDate, setBeginBuyAutoStartDate] =
    useState<string>("");
  const [currentSession, setCurrentSession] = useState<any>(null);
  const [excuseTempVolumn, setExcuseTempVolumn] = useState<any>(null);
  const [bankSuperVisory, setBankSuperVisory] = useState<any>(null);
  const [rmCode, setRmCode] = useState<string>("");
  const [orderData, setOrderData] = useState<any>({});
  // control header
  const [loading, setLoading] = useState<boolean>(false);

  const scrollViewRef = useRef<any>(null);

  useEffect(() => {
    if (initData?.product) {
      onChangeProduct(initData?.product);
    }
    if (initData?.scheme) {
      setScheme(initData?.scheme);
    }
    return () => {};
  }, [initData]);

  useEffect(() => {
    getDataFirst();
    return () => {};
  }, []);

  const getDataFirst = async () => {
    try {
      const resListProduct = await apiInvestment.loadProduct({});
      if (resListProduct.status == 200) {
        setListProduct(resListProduct.data);
      }
    } catch (err: any) {
    } finally {
    }
  };

  const clearData = () => {
    setProduct(null);
    setScheme(null);
    setListScheme([]);
    setAmount("");
    setBeginBuyAutoStartDate("");
    setCurrentSession(null);
    setExcuseTempVolumn(null);
    setBankSuperVisory(null);
  };

  const onNext = () => {
    setStepTimeLine(stepTimeLine + 1);
    return;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        x: (stepTimeLine || 1) * widthScreen,
        animated: false,
      });
    }
  };
  const onSetCurrentSession = async () => {
    const ses = await apiInvestment.checkOverCurrentSession({
      productId: product?.id,
    });
    if (ses.status == 200) {
      setCurrentSession(ses.data);
    }
  };
  const onPre = () => {
    setStepTimeLine(stepTimeLine - 1);
    return;
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        x: stepTimeLine ? (stepTimeLine - 2) * widthScreen : widthScreen,
        animated: false,
      });
    }
  };
  const onPre1 = () => {
    if (scrollViewRef.current) {
      clearData();
      scrollViewRef.current.scrollTo({
        x: stepTimeLine ? (stepTimeLine - 3) * widthScreen : widthScreen,
        animated: true,
      });
    }
  };

  const onScroll = (e: any) => {
    const offset = Math.round(e.nativeEvent.contentOffset.x / widthScreen) + 1;
    if (offset != stepTimeLine) {
      setStepTimeLine(offset);
    }
  };
  //

  const onExcuseTempVolumn = async () => {
    try {
      const res = await apiInvestment.excuseTempVolume({
        amount: amount.replace(/[,|.]/g, ""),
        productId: product?.id,
        productProgramId: scheme.id,
      });
      if (res.status == 200) {
        setExcuseTempVolumn(res.data);
        return;
      }
      setExcuseTempVolumn(null);
      return;
    } catch (error: any) {
      setExcuseTempVolumn(null);
      Alert.showError({
        content: I18nState == "vi" ? error.message : error.messageEn,
        multilanguage: false,
      });
    }
  };

  const onChangeProduct = async (e: any) => {
    setLoading(true);
    setProduct(e);
    setScheme(null);
    setAmount("");
    try {
      const res = await apiInvestment.loadBuy({
        id: e.id,
      });
      if (res.status == 200) {
        setListScheme(res.data);
      }
      const ses = await apiInvestment.checkOverCurrentSession({
        productId: e.id,
      });
      if (ses.status == 200) {
        setCurrentSession(ses.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const switchStep = (step) => {
    switch (step) {
      case 1:
        return (
          <OrderBuyStep1
            loading={loading}
            onNext={onNext}
            product={product}
            listScheme={listScheme}
            setListScheme={setListScheme}
            onExcuseTempVolumn={onExcuseTempVolumn}
            onChangeProduct={onChangeProduct}
            listProduct={listProduct}
            setProduct={setProduct}
            scheme={scheme}
            setScheme={setScheme}
            amount={amount}
            beginBuyAutoStartDate={beginBuyAutoStartDate}
            setBeginBuyAutoStartDate={setBeginBuyAutoStartDate}
            setAmount={setAmount}
            currentSession={currentSession}
            setCurrentSession={setCurrentSession}
            setExcuseTempVolumn={setExcuseTempVolumn}
            excuseTempVolumn={excuseTempVolumn}
            bankSuperVisory={bankSuperVisory}
            setBankSuperVisory={setBankSuperVisory}
            onSetCurrentSession={onSetCurrentSession}
            rmCode={rmCode}
            setRmCode={setRmCode}
          />
        );
      case 2:
        return (
          <OrderBuyStep2
            stepTimeLine={stepTimeLine}
            rmCode={rmCode}
            onNext={onNext}
            onPre={onPre}
            setOrderData={setOrderData}
            product={product}
            beginBuyAutoStartDate={beginBuyAutoStartDate}
            scheme={scheme}
            amount={amount}
            currentSession={currentSession}
            excuseTempVolumn={excuseTempVolumn}
            bankSuperVisory={bankSuperVisory}
          />
        );
      case 3:
        return (
          <OrderBuyStep3
            beginBuyAutoStartDate={beginBuyAutoStartDate}
            stepTimeLine={stepTimeLine}
            orderData={orderData}
            excuseTempVolumn={excuseTempVolumn}
            bankSuperVisory={bankSuperVisory}
            amount={amount}
            currentSession={currentSession}
            onPre={onPre1}
            product={product}
            scheme={scheme}
          />
        );
      default:
        return <Div />;
    }
  };

  return (
    <Div backgroundColor={Ecolors.whiteColor} width={"100%"}>
      {switchStep(stepTimeLine)}
    </Div>
  );
}

export default React.memo(OrderBuy);
