import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { toastRef } from "components/Toast";
import ToastRoot from "components/Toast/ToastRoot";
import * as React from "react";
import { ColorSchemeName, Linking } from "react-native";
import { Provider, useDispatch } from "react-redux";
import ControlEKYCScreen from "screens/ControlEKYCScreen";
import EditBankInfoModal from "screens/EditBankInfoModal";
import LoginScreen from "screens/LoginScreen";
import OtpRequestModal from "screens/OtpRequestModal";
import Register from "screens/Register";
import RenderHtmlEKYC from "screens/RenderHtmlEKYC";
import SetPasswordScreen from "screens/SetPasswordScreen";
import { navigationRef } from "services/navigation";
import LinkingConfiguration from "./LinkingConfiguration";
import {
  screenOptions,
  translateXOptionsScreen,
  translateYModal,
} from "./optionsNavigate";

import ReviewInfoModal from "screens/ReviewInfoModal";

import { alertRef } from "components/BottomSheetDialog/Alert";
import AlertCp from "components/BottomSheetDialog/AlertCp";
import DropdownModal from "components/DropdownModal";
import { loadAsset, loadProduct } from "reducer/asset";
import { getInfo, getQuestion } from "reducer/authen";
import AssetScreen from "screens/AssetScreen";
import ListAssetDetailsModal from "screens/AssetScreen/ListAssetDetailsModal";
import ProgramDetailsModal from "screens/AssetScreen/ProgramDetailsModal";
import CreateOrderModal from "screens/CreateOrderModal";
import DigitalSignatureScreen from "screens/DigitalSignature/DigitalSignatureScreen";
import SignatureDraw from "screens/DigitalSignature/SignatureDraw";
import PendingOrderScreen from "screens/PendingOrderScreen";
import PerformanceScreen from "screens/PerformanceScreen";
import Chart from "screens/PerformanceScreen/Chart";
import ProductScreen from "screens/ProductScreen";
import RiskQuestionScreen from "screens/RiskQuestionScreen";
import EditRiskQuestionModal from "screens/RiskQuestionScreen/EditRiskQuestionModal";
import HistoryRiskQuestionModal from "screens/RiskQuestionScreen/HistoryRiskQuestionModal";
import ResultModal from "screens/RiskQuestionScreen/ResultModal";
import RiskContentModal from "screens/RiskQuestionScreen/RiskContentModal";
import { store } from "store";
import { useAppSelector } from "store/hooks";
import { Log, setStoreData } from "utils";
import { detectLinkData, linkToWeb } from "utils/utils";
// import FeeTableModal from "screens/CreateOrderModal/FeeTableModal";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <Provider store={store}>
      <NavigationContainer
        ref={navigationRef}
        linking={LinkingConfiguration}
        theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      >
        <ToastRoot ref={toastRef} />
        <AlertCp ref={alertRef} />
        <RootNavigator />
      </NavigationContainer>
    </Provider>
  );
}

const Stack = createStackNavigator();

function RootNavigator() {
  const dispatch = useDispatch();
  const statusScreen = useAppSelector((state) => state.authen.statusScreen);

  React.useEffect(() => {
    gotolinktoapp();
    bindDataUrl();
    return () => {};
  }, []);

  const gotolinktoapp = () => {
    Log("gotooo");
    linkToWeb("https://VinaCapitalMio");

    // var assetlinks = fs.readFileSync(__dirname + "/static/assetlinks.json");
    // app.get("/.well-known/assetlinks.json", function (req, res, next) {
    //   res.set("Content-Type", "application/json");
    //   res.status(200).send(assetlinks);
    // });
  };

  const bindDataUrl = async () => {
    Linking.getInitialURL()
      .then((r: any) => {
        const obj: any = detectLinkData(r);
        setStoreData("idNo", obj?.idNo || "");
        setStoreData("phone", obj?.phone || "");
        setStoreData("refCode", obj?.refCode || "");
        setStoreData("bankId", obj?.bankId || "");
        setStoreData("bankNumber", obj?.bankNumber || "");
        setStoreData("productId", obj?.productId || "");
        setStoreData("mToken", obj?.token || "");
        setStoreData("idCode", obj?.idCode || "");
      })
      .catch((err: any) => {})
      .finally(() => {
        if (statusScreen == "main") {
          dispatch(getInfo({}));
          dispatch(getQuestion({}));
          dispatch(loadProduct({}));
          dispatch(loadAsset({}));
        }
      });
  };
  return (
    <Stack.Navigator
      screenOptions={screenOptions}
      initialRouteName={"AssetScreen"}
    >
      <Stack.Screen
        name="LoginScreen"
        key="LoginScreen"
        component={LoginScreen}
        options={translateXOptionsScreen}
      />
      <Stack.Screen
        name="AssetScreen"
        key="AssetScreen"
        component={AssetScreen}
        options={translateXOptionsScreen}
      />
      <Stack.Screen
        name="RiskQuestionScreen"
        key="RiskQuestionScreen"
        component={RiskQuestionScreen}
        options={translateXOptionsScreen}
      />
      <Stack.Screen
        name="PerformanceScreen"
        key="PerformanceScreen"
        component={PerformanceScreen}
        options={translateXOptionsScreen}
      />
      <Stack.Screen
        name="Chart"
        key="Chart"
        component={Chart}
        options={translateXOptionsScreen}
      />
      <Stack.Screen
        name="PendingOrderScreen"
        key="PendingOrderScreen"
        component={PendingOrderScreen}
        options={translateXOptionsScreen}
      />
      <Stack.Screen
        name="ProductScreen"
        key="ProductScreen"
        component={ProductScreen}
        options={translateXOptionsScreen}
      />
      <Stack.Screen
        name="ProgramDetailsModal"
        key="ProgramDetailsModal"
        component={ProgramDetailsModal}
        options={translateYModal}
      />
      <Stack.Screen
        name="HistoryRiskQuestionModal"
        key="HistoryRiskQuestionModal"
        component={HistoryRiskQuestionModal}
        options={translateYModal}
      />
      <Stack.Screen
        name="RiskContentModal"
        key="RiskContentModal"
        component={RiskContentModal}
        options={translateYModal}
      />
      <Stack.Screen
        name="EditRiskQuestionModal"
        key="EditRiskQuestionModal"
        component={EditRiskQuestionModal}
        options={translateYModal}
      />
      <Stack.Screen
        name="ResultModal"
        key="ResultModal"
        component={ResultModal}
        options={translateYModal}
      />
      <Stack.Screen
        name="ListAssetDetailsModal"
        key="ListAssetDetailsModal"
        component={ListAssetDetailsModal}
        options={translateYModal}
      />
      <Stack.Screen
        name="Register"
        key="Register"
        component={Register}
        options={translateXOptionsScreen}
      />
      <Stack.Screen
        name="OtpRequestModal"
        key="OtpRequestModal"
        component={OtpRequestModal}
        options={translateYModal}
      />
      <Stack.Screen
        name="SetPasswordScreen"
        key="SetPasswordScreen"
        component={SetPasswordScreen}
        options={translateXOptionsScreen}
      />
      <Stack.Screen
        name="ControlEKYCScreen"
        key="ControlEKYCScreen"
        component={ControlEKYCScreen}
        options={translateXOptionsScreen}
      />
      <Stack.Screen
        name="DigitalSignatureScreen"
        key="DigitalSignatureScreen"
        component={DigitalSignatureScreen}
        options={translateXOptionsScreen}
      />
      <Stack.Screen
        name="SignatureDraw"
        key="SignatureDraw"
        component={SignatureDraw}
        options={translateYModal}
      />
      <Stack.Screen
        name="EditBankInfoModal"
        key="EditBankInfoModal"
        component={EditBankInfoModal}
        options={translateYModal}
      />
      <Stack.Screen
        name="CreateOrderModal"
        key="CreateOrderModal"
        component={CreateOrderModal}
        options={translateYModal}
      />
      {/* <Stack.Screen
        name="FeeTableModal"
        key="FeeTableModal"
        component={FeeTableModal}
        options={translateYModal}
      /> */}
      {/* <Stack.Screen
        name="CreateOrderModal"
        key="CreateOrderModal"
        component={CreateOrderModal}
        options={translateYModal}
      /> */}
      {/* <Stack.Screen
        name="FeeTableModal"
        key="FeeTableModal"
        component={FeeTableModal}
        options={translateYModal}
      /> */}
      <Stack.Screen
        name="DropdownModal"
        key="DropdownModal"
        component={DropdownModal}
        options={translateYModal}
      />
      <Stack.Screen
        name="ReviewInfoModal"
        key="ReviewInfoModal"
        component={ReviewInfoModal}
        options={translateYModal}
      />
      <Stack.Screen
        name="RenderHtmlEKYC"
        key="RenderHtmlEKYC"
        component={RenderHtmlEKYC}
        options={translateYModal}
      />
    </Stack.Navigator>
  );
}
