import Button from "components/Button";
import Div from "components/Div";
import ImageView from "components/ImageView";
import Label from "components/Label";
import crc from "crc-react-native";
import React, { useEffect, useRef, useState } from "react";
import QQ from "react-native-qrcode-svg";

import { HTMLView } from "components";
import { Icons } from "constants/Icons";
import { Ecolors, Efonts } from "constants/themes";
import html2canvas from "html2canvas";
import { ActivityIndicator, Modal, View } from "react-native";
import { useAppSelector } from "store/hooks";
import { Log, widthScale } from "utils";
import { PostMSG } from "utils/utils";

interface IPayment {
  amount: number;
  accountName: string;
  accountNo: string;
  transferContent: string;
  bankId: string;
}
const QRCode = (p: { data: any }) => {
  const viewshotRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [qrImg, setQrImg] = useState<string>("");
  const [showIntroduce, setShowIntroduce] = useState<boolean>(false);
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  const html =
    I18nState == "vi"
      ? `<p><strong>1.  </strong>Nhấn vào <strong>"Tải về"</strong> để tải hình QR và lưu trên điện thoại của bạn.</p>
  <p><strong>2.  </strong>Mở ứng dụng ngân hàng <strong>(Mobile Banking)</strong> của bạn và chọn chức năng QR Code.</p>
  <p><strong>3.  </strong>Chọn <strong>hình QR đã tải về</strong>, đối chiếu thông tin và thực hiện chuyển khoản.</p>
  `
      : `<p><strong>1.  </strong>Click on button <strong>"Download"</strong> to download the QR image and save it on your phone.</p>
  <p><strong>2.  </strong>Open the banking app <strong>(Mobile Banking)</strong> your and select the QR Code function.</p>
  <p><strong>3.  </strong>Choose <strong>QR image downloaded</strong>, compare information and make a transfer.</p>
  `;

  useEffect(() => {
    const t = generateQr(p.data);
    setQrImg(t);
  }, []);

  const generateQr = (payment: IPayment) => {
    const FIELD1 = "00020101021238";
    const FIELD2 = "0010A00000072701";
    const FIELD3 = "0208QRIBFTTA";
    const FIELD4 = "530370454";
    const FIELD5 = "5802VN62";
    let dataString = FIELD1;
    const s1_1 = FIELD2;
    const s1_2 =
      "00" +
      formatLengthCode(`${payment.bankId}`) +
      payment.bankId +
      "01" +
      formatLengthCode(payment.accountNo) +
      payment.accountNo;

    const s1_3 = FIELD3;
    const s1 = `${s1_1}${formatLengthCode(s1_2)}${s1_2}${s1_3}`;
    dataString = `${dataString}${formatLengthCode(
      s1
    )}${s1}${FIELD4}${formatLengthCode(payment.amount.toString())}${
      payment.amount
    }${FIELD5}`;
    const s2 =
      "08" +
      formatLengthCode(payment.transferContent) +
      payment.transferContent;
    dataString = `${dataString}${formatLengthCode(s2)}${s2}6304`;
    let crcString = crc.crc16ccitt(dataString).toString(16);
    for (let i = 0; i < 4 - crcString.length; i++) {
      crcString = "0" + crcString;
    }
    return `${dataString}${crcString}`;
  };

  const formatLengthCode = (content: string) => {
    if (!content) {
      return "00";
    } else {
      if (content.length < 10) {
        return `0${content.length}`;
      } else {
        return `${content.length}`;
      }
    }
  };

  const onSaveQRCode = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const body: any = document.getElementById("View");
      viewshotRef.current.measure((a, b, width, height, px, py) => {
        html2canvas(body).then((canvas) => {
          let croppedCanvas = document.createElement("canvas");
          let croppedCanvasContext = croppedCanvas.getContext("2d");
          //
          croppedCanvas.width = width;
          croppedCanvas.height = height;
          croppedCanvasContext?.drawImage(canvas, 0, 0, width, height);
          //
          const downloadLink = document.createElement("a");
          const fileName = "QRCode.png";
          const href = croppedCanvas.toDataURL("image/png");
          //
          downloadLink.href = href;
          downloadLink.download = fileName;
          PostMSG(
            "DownloadQRCode",
            `${href.replace("data:image/png;base64,", "") || ""}`
          );
          downloadLink.click();
        });
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Div
      borderRadius={8}
      padding={15}
      borderWidth={0.8}
      marginTop={5}
      alignItems={"center"}
      borderColor={Ecolors.bordercolor}
      backgroundColor={Ecolors.whiteColor}
    >
      <Label
        fontWeight={"700"}
      >{`createordermodal.thanhtoannhanhquaqrcode`}</Label>

      {!!qrImg ? (
        <>
          <Button onPress={() => setShowIntroduce(true)}>
            <Label
              size={14}
              color={Ecolors.linkColor}
              marginBottom={10}
              marginTop={3}
            >{`createordermodal.huongdanchuyenkhoan`}</Label>
          </Button>
          <Div
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <View nativeID="View" ref={viewshotRef}>
              <Div
                borderRadius={8}
                backgroundColor={Ecolors.whiteColor}
                padding={5}
              >
                <QQ
                  size={widthScale(100)}
                  backgroundColor={Ecolors.whiteColor}
                  value={qrImg || "1231"}
                />
              </Div>
            </View>
            <Button
              widthHeight={100}
              justifyContent={"center"}
              onPress={onSaveQRCode}
              alignItems={"center"}
            >
              {loading ? (
                <ActivityIndicator size={"large"} color={Ecolors.mainColor} />
              ) : (
                <>
                  <ImageView
                    width={30}
                    height={23}
                    resizeMode={"contain"}
                    source={Icons.downloadqr}
                  />
                  <Label marginTop={6}>{`createordermodal.taive`}</Label>
                </>
              )}
            </Button>
          </Div>
          <Div
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            marginTop={10}
            width={"100%"}
          >
            <ImageView
              widthHeight={16}
              source={Icons.warningamount}
              marginRight={7}
            />
            <Div flex={1}>
              <Label
                size={14}
                fontFamily={Efonts.italic}
              >{`createordermodal.motsonganhangchuahotroquetmaqr`}</Label>
            </Div>
          </Div>
          {/* <Div
            flexDirection={'row'}
            alignItems={'center'}
            marginTop={10}
            justifyContent={'flex-start'}
            width={'100%'}>
            <ImageView
              widthHeight={16}
              source={Icons.warningamount}
              resizeMode={'contain'}
              marginRight={7}
            />
            <Div flex={1}>
              <Label
                size={14}
                fontFamily={
                  Efonts.italic
                }>{`createordermodal.quykhachvuilongdoichieuthongtintruockhichuyenkhoan`}</Label>
            </Div>
          </Div> */}
        </>
      ) : (
        <Button
          onPress={() => {
            const t = generateQr(p.data);
            setQrImg(t);
          }}
        >
          <Label
            color={Ecolors.linkColor}
          >{`createordermodal.xemqrcode`}</Label>
        </Button>
      )}
      <Modal animationType="slide" transparent={true} visible={showIntroduce}>
        <Div
          flex={1}
          backgroundColor={Ecolors.transparentLoading}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Div
            width={337}
            backgroundColor={Ecolors.whiteColor}
            borderRadius={5}
            minHeight={200}
          >
            <Div
              flexDirection={"row"}
              alignItems={"center"}
              paddingHorizontal={15}
              borderBottomWidth={0.8}
              borderBottomColor={Ecolors.bordercolor}
              paddingVertical={15}
              justifyContent={"space-between"}
            >
              <Label
                fontWeight={"700"}
              >{`createordermodal.huongdanthanhtoanbangqrcode`}</Label>
              <Button
                onPress={() => {
                  setShowIntroduce(false);
                }}
              >
                <ImageView
                  widthHeight={16}
                  source={Icons.close}
                  resizeMode={"contain"}
                />
              </Button>
            </Div>
            <Div padding={15}>
              <HTMLView source={{ html }} />
            </Div>
          </Div>
        </Div>
      </Modal>
    </Div>
  );
};

export default React.memo(QRCode);
