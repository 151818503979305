import { Alert, Button, ButtonBorder, Div, Label } from "components";
import { EStyle, Ecolors } from "constants/themes";
import React, { useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { apiInvestment } from "services/apiInvestment";
import { navigate } from "services/navigation";
import { useAppSelector } from "store/hooks";
import { heightScale, widthScreen } from "utils";
import { convertNumber, convertTimestamp, copyToClipboard } from "utils/utils";

interface Props {
  product: any;
  scheme: any;
  amount: any;
  beginBuyAutoStartDate: any;
  currentSession: any;
  excuseTempVolumn: any;
  bankSuperVisory: any;
  rmCode?: string;
  stepTimeLine?: number;
  setOrderData?: (t: any) => void;
  onNext?: () => void;
  onPre?: () => void;
}

function RowSpaceItem(p: {
  marginTop?: number;
  paddingHorizontal?: number;
  children?: any;
  isBorderBottom?: boolean;
}) {
  return (
    <>
      <Div
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginTop={p.marginTop ?? 0}
        paddingHorizontal={p.paddingHorizontal ?? 0}
      >
        {p.children && p.children}
      </Div>
      {p.isBorderBottom && (
        <Div
          marginTop={15}
          width={"100%"}
          height={1}
          backgroundColor={Ecolors.spaceColor}
        />
      )}
    </>
  );
}

function ContentCoppy(p: {
  title: string;
  content: string;
  isBorderBottom?: boolean;
  marginTop?: number;
  isBtn?: boolean;
}) {
  return (
    <RowSpaceItem marginTop={p.marginTop} isBorderBottom={p.isBorderBottom}>
      <Div flex={1}>
        <Label color={Ecolors.grayColor} size={14}>
          {p.title}
        </Label>
        <Label marginTop={5} multilanguage={false} size={14}>
          {p.content}
        </Label>
      </Div>
      {p.isBtn ? (
        <Button
          onPress={() => {
            copyToClipboard(p.content);
          }}
          width={61}
          height={26}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={5}
          backgroundColor={Ecolors.yellowColor}
        >
          <Label size={14} multilanguage={false}>
            COPY
          </Label>
        </Button>
      ) : (
        <Div width={61} height={26} />
      )}
    </RowSpaceItem>
  );
}

function OrderBuyStep2({
  product,
  scheme,
  amount,
  currentSession,
  excuseTempVolumn,
  bankSuperVisory,
  stepTimeLine,
  setOrderData,
  beginBuyAutoStartDate,
  onNext,
  rmCode,
  onPre,
}: Props) {
  // console.log('adasda', {
  //   product,
  //   scheme,
  //   amount,
  //   currentSession,
  //   excuseTempVolumn,
  //   bankSuperVisory,
  // });
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  const [currentTab, setCurrentTab] = useState(0);
  const data = {
    amount: amount?.replace(/,/g, ""),
    accountName: bankSuperVisory?.name,
    accountNo: bankSuperVisory?.number,
    transferContent: ` ${excuseTempVolumn?.investmentNumber}`,
    bankId: bankSuperVisory?.dataBank?.binCode,
    bankName: bankSuperVisory?.dataBank?.name,
  };

  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setLoading(true);
      const orderData = {
        amount: amount.replace(/[,]/g, "") || 0,
        beginBuyAutoStartDate: parseInt(beginBuyAutoStartDate) || null,
        closedBankNoteTime: currentSession?.closedBankNoteTime || 0,
        closedOrderBookTime: currentSession?.closedOrderBookTime || 0,
        closedBankNoteTimeString:
          currentSession?.closedBankNoteTimeString || "0",
        closedOrderBookTimeString:
          currentSession?.closedOrderBookTimeString || "0",
        createdDate: new Date().getTime(),
        investmentNumber: excuseTempVolumn?.investmentNumber || "0",
        isBuyAuto: scheme?.productSchemeIsAutoBuy ? 1 : 0 || 0,
        productId: product?.id || 0,
        productName: product?.name || "",
        productProgramId: scheme?.id || 0,
        productSchemeCode: scheme?.productSchemeCode || "",
        productSchemeIsAutoBuy: scheme?.productSchemeIsAutoBuy || false,
        productSchemeNameEn: scheme?.productSchemeNameEn || "",
        tradeCode: scheme?.tradeCode || "",
        tradingTime: currentSession?.tradingTime || 0,
        tradingTimeString: currentSession?.tradingTimeString || "",
      };
      const res = await apiInvestment.createBuyOrder(orderData);
      if (res.status == 200) {
        const updateRmCodeResponse = await apiInvestment.updateRmCode({
          orderCode: res.data.orderCode,
          rmCode: rmCode || "",
        });

        setOrderData &&
          setOrderData({
            orderData: {
              ...orderData,
              productCode: product.code,
              rmCode: updateRmCodeResponse.data.rmCode || "",
            },
            data: res.data,
          });
        if (res.data.otpInfo) {
          navigate("OtpRequestModal", {
            data: {
              requestOnSendOtp: res.data.otpInfo,
              flowApp: "CreateOrderBuy",
            },
            onConfirm: () => {
              onNext && onNext();
            },
          });
          return;
        }
        onNext && onNext();
        return;
      }
      Alert.showError({
        content: I18nState == "vi" ? res.message : res.messageEn,
        multilanguage: false,
      });
      return;
    } catch (error: any) {
      Alert.showError({
        content: I18nState == "vi" ? error.message : error.messageEn,
        multilanguage: false,
      });
      return;
    } finally {
      setLoading(false);
    }
  };

  if (stepTimeLine != 2) {
    return <Div screen={true} />;
  }
  return (
    <Div screen={true}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Div paddingHorizontal={16}>
          <Label
            fontWeight={"700"}
            marginTop={18}
          >{`createordermodal.phuongphapthanhtoan`}</Label>

          {/* thong tin chuyen khoan */}
          {/* <Div
            flexDirection={'row'}
            alignItems={'center'}
            marginTop={13}
            justifyContent={'flex-start'}>
            <Div
              widthHeight={20}
              alignItems={'center'}
              justifyContent={'center'}
              borderRadius={20}
              borderWidth={0.8}
              marginRight={8}
              borderColor={Ecolors.mainColor}>
              <Div
                widthHeight={12}
                backgroundColor={Ecolors.mainColor}
                borderRadius={20}
              />
            </Div>
            <Label>{`createordermodal.chuyenkhoanquanganhang`}</Label>
          </Div> */}
          <Div
            flexDirection={"row"}
            marginTop={5}
            flexWrap={"wrap"}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Label multilanguage={false}>
              <Label
                size={14}
              >{`createordermodal.thoidiemdongsolenhnhantien`}</Label>
              <Label
                size={14}
                color={Ecolors.linkColor}
                multilanguage={false}
                marginLeft={3}
              >
                {` ${currentSession?.closedBankNoteTimeString}`}
                {I18nState == "vi" ? " (Giờ VN)" : " (VNT)"}
              </Label>
            </Label>
          </Div>
          {/* <Label
            fontWeight={'700'}
            marginTop={18}>{`createordermodal.thongtinchuyenkhoan`}</Label> */}
          {/* <Div
            flexDirection="row"
            justifyContent="space-around"
            width="100%"
            marginVertical={15}
          >
            <ButtonBorder
              size={13.5}
              width={176}
              height={30}
              onPress={() => {
                setCurrentTab(0);
              }}
              type={currentTab == 0 ? 5 : 4}
              title={`createordermodal.thanhtoanbangqrcode`}
            />
            <ButtonBorder
              size={13.5}
              width={170}
              height={30}
              onPress={() => {
                setCurrentTab(1);
              }}
              type={currentTab == 1 ? 5 : 4}
              title={`createordermodal.thongtinchuyenkhoan`}
            />
          </Div> */}

          {/* {currentTab == 0 && <QRCode data={data} />}
          {currentTab == 1 && (
            <Div>
              <Label marginTop={3}>{`createordermodal.luuyttck`}</Label>
              <Div
                width={"100%"}
                marginTop={9}
                borderRadius={8}
                borderWidth={0.8}
                borderColor={Ecolors.bordercolor}
                backgroundColor={Ecolors.whiteColor}
                paddingHorizontal={20}
                paddingTop={20}
                paddingBottom={24}
              >
                <ContentCoppy
                  title={`createordermodal.tenthuhuong`}
                  isBtn={true}
                  content={
                    I18nState == "vi"
                      ? bankSuperVisory?.name
                      : bankSuperVisory?.nameEn || bankSuperVisory?.name
                  }
                  isBorderBottom={true}
                />
                <ContentCoppy
                  marginTop={11}
                  isBtn={true}
                  title={`createordermodal.sotaikhoan`}
                  content={bankSuperVisory?.number || ""}
                  isBorderBottom={true}
                />
                <ContentCoppy
                  marginTop={11}
                  title={`createordermodal.nganhang`}
                  isBtn={false}
                  content={
                    I18nState == "vi"
                      ? bankSuperVisory?.dataBank?.name
                      : bankSuperVisory?.dataBank?.nameEn || ""
                  }
                  isBorderBottom={true}
                />
                <ContentCoppy
                  marginTop={11}
                  isBtn={true}
                  isBorderBottom={!!scheme?.productSchemeIsAutoBuy}
                  title={`createordermodal.noidung`}
                  // content={`${currentUser?.name || ''}-${
                  //   excuseTempVolumn?.investmentNumber
                  // }-${scheme?.tradeCode || ''}`}
                  content={excuseTempVolumn?.investmentNumber || ""}
                />
                {scheme && scheme?.productSchemeIsAutoBuy && (
                  <>
                    <RowSpaceItem marginTop={11}>
                      <Label
                        size={14}
                      >{`createordermodal.tudongtieptucdautu`}</Label>
                      <Label multilanguage={false} size={14}>
                        {I18nState == "vi" ? "Có" : "Yes"}
                      </Label>
                    </RowSpaceItem>
                    <RowSpaceItem marginTop={8}>
                      <Label
                        size={14}
                      >{`createordermodal.ngaythanhtoanhangthang`}</Label>
                      <Label multilanguage={false} size={14}>
                        {beginBuyAutoStartDate || ""}
                      </Label>
                    </RowSpaceItem>
                  </>
                )}
              </Div>
            </Div>
          )} */}
          {/* <Div
            flexDirection={'row'}
            marginVertical={15}

            alignItems={'center'}
            justifyContent={'space-between'}>
            <Div height={0.8} width={133} backgroundColor={Ecolors.bordercolor} />
            <Label>{`createordermodal.hoac`}</Label>
            <Div height={0.8} width={133} backgroundColor={Ecolors.bordercolor} />
          </Div> */}
          <Label
            marginTop={20}
            fontWeight={"700"}
          >{`createordermodal.thongtindautu`}</Label>
          <Div
            width={"100%"}
            marginTop={9}
            borderRadius={8}
            borderWidth={0.8}
            borderColor={Ecolors.bordercolor}
            backgroundColor={Ecolors.whiteColor}
            style={EStyle.shadowItem}
            paddingHorizontal={20}
            paddingTop={20}
            paddingBottom={24}
          >
            <RowSpaceItem>
              <Label size={14}>{`createordermodal.sotienmua`}</Label>

              <Label multilanguage={false} size={14}>
                {convertNumber(amount)}
              </Label>
            </RowSpaceItem>
            <Div borderBottomColor={Ecolors.spaceColor} borderBottomWidth={1}>
              <Label
                multilanguage={false}
                size={14}
                marginTop={8}
                marginLeft={30}
                marginBottom={15}
                style={{
                  fontStyle: "italic",
                  textAlign: "right",
                }}
              >
                {/*  {I18nState == 'vi' ? doc(amount.replace(/[,]/g, '')).charAt(1).toUpperCase() +
                doc(amount.replace(/[,]/g, '')).slice(2) + ' đồng' :
                converter.toWords(Number(amount.replace(/[,]/g, ''))) + ' dong'

              } */}
                {I18nState == "vi"
                  ? excuseTempVolumn?.amountText
                  : excuseTempVolumn?.amountTextEn}
              </Label>
            </Div>

            <RowSpaceItem marginTop={15} isBorderBottom={true}>
              <Label size={14}>{`createordermodal.quydautu`}</Label>
              <Div
                flex={1}
                flexDirection={"row"}
                alignItems={"center"}
                paddingLeft={10}
                justifyContent={"flex-end"}
              >
                <Label size={14} multilanguage={false}>
                  {I18nState == "vi" ? product?.name : product?.nameEn || ""}
                </Label>
              </Div>
            </RowSpaceItem>
            <RowSpaceItem marginTop={15} isBorderBottom={true}>
              <Label size={14}>{`createordermodal.chuongtrinh`}</Label>
              <Div
                flex={1}
                flexDirection={"row"}
                paddingLeft={10}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Label multilanguage={false} size={14}>
                  {I18nState == "vi" ? scheme?.name : scheme?.nameEn}
                </Label>
              </Div>
            </RowSpaceItem>
            <RowSpaceItem marginTop={15} isBorderBottom={true}>
              <Label size={14}>{`createordermodal.loailenh`}</Label>
              <Label size={14}>{`createordermodal.mua`}</Label>
            </RowSpaceItem>
            <RowSpaceItem marginTop={15} isBorderBottom={true}>
              <Label size={14}>{`createordermodal.ngaydatlenh`}</Label>
              <Label multilanguage={false} size={14}>
                {convertTimestamp(new Date().getTime(), "DD/MM/yyyy, HH:mm")}
                {I18nState == "vi" ? " (Giờ VN)" : " (VNT)"}
              </Label>
            </RowSpaceItem>
            <RowSpaceItem marginTop={15}>
              <Label size={14}>{`createordermodal.phiengiaodich`}</Label>
              <Label multilanguage={false} size={14}>
                {currentSession?.tradingTimeString}
                {I18nState == "vi" ? " (Giờ VN)" : " (VNT)"}
              </Label>
            </RowSpaceItem>
          </Div>
        </Div>

        <Div height={100} />
      </ScrollView>
      <RowSpaceItem paddingHorizontal={29} marginTop={10}>
        <ButtonBorder
          width={148}
          height={48}
          type={2}
          onPress={() => {
            // goBack();
            onPre && onPre();
          }}
          title={`createordermodal.quaylai`}
        />
        <ButtonBorder
          width={148}
          loading={loading}
          onPress={() => {
            onConfirm();
          }}
          height={48}
          title={`createordermodal.xacnhan`}
        />
      </RowSpaceItem>

      <Div height={190} />
    </Div>
  );
}
const s = StyleSheet.create({
  scrollviewHeader: {
    width: widthScreen,
    height: heightScale(50),
    flexDirection: "row",
  },
});
export default React.memo(OrderBuyStep2);
