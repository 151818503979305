import { useRoute } from "@react-navigation/core";
import {
  Alert,
  ButtonBorder,
  Div,
  HeaderBack,
  ImageView,
  Label,
} from "components";
import { urlApp } from "constants/AppUrl";
import { Icons } from "constants/Icons";
import React, { useEffect, useRef, useState } from "react";
import { View } from "react-native";
import { apiAuth } from "services/api";
import { apiMain } from "services/apiMain";
import { navigate } from "services/navigation";
import { useAppSelector } from "store/hooks";
import { Log, getStoreData, setStoreData, setStoreToken } from "utils";
import { PostMSG, convertStringTime } from "utils/utils";

function ControlEKYCScreen() {
  const route = useRoute<any>();
  // const route = {
  //   params: {
  //     data: {
  //       confirmPassword: "Kien12",
  //       email: "kienn@gmail.com",
  //       name: "kienn",
  //       password: "Kien12",
  //       phone: "0352312322",
  //       phonePostal: "+84",
  //       provinceId: 7,
  //       userRefCode: null,
  //       username: "0352312322",
  //     },
  //   },
  // };
  const countTime = useRef<number>(1);
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const I18nState = useAppSelector((state) => state.languages.I18nState);

  const apisConfig = {
    baseApi: "https://sdk-uat.trueid.ai",
    apiPath: "/ekyc/v1.2",
    facetecAssetsUrl: "/trueIDSDK/",
    // use `authorization` and `clientid` headerConfigJSON
    // in case you need custom accessToken, `useClientId` must be false
    headerConfigJSON: JSON.stringify({
      authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.ewoiY2xpZW50X2lkIjogIjNjNGJkYTJkZmJhZmQ1YTgyYzE2ZWFkYTk3Yzg0OTllIiwKImNyZWF0ZWRfYXQiOiAxNzA5Mjg3NTg1Mzc5LAoiY29tcGFueV9uYW1lIiA6ICIiLAoiY2xpZW50X3R5cGUiIDogIiIsCiJyb2xlIjogIkNMSUVOVCIsCiJlbWFpbCIgOiAiYWRtaW4tdWF0LXRlc3RAdm5nLmNvbS52biIsCiJ1c2VybmFtZSI6ICJhZG1pbi11YXQtdGVzdEB2bmcuY29tLnZuIiwKInNjb3BlIiA6ICJPQ1IsIERFVEVDVCwgVkVSSUZZLCBDT01QQVJFIiwKImlhdCIgOiAxNzA5Mjg3NTg1LAoiZXhwIiA6IDE3MTA1ODM1ODUKfQ.oqAu6QifoCmzC_WnIxwZ5J-NzjYlcycCM8GMIef4g8U",
      clientid: "ea8df9ebff8d38479058d7f1d235e097",
    }),
  };

  const config = {
    useClientId: true,
    clientId: "ea8df9ebff8d38479058d7f1d235e097",
    clientSecret: "+xzqA0O4GScV2dSiaB2cDiYVDY7hE0pG6rqN0TTNbU4=",
    scanCCCDQR: false,
    steps: ["id_front", "id_back", "liveness"],

    allowEditOcr: true,

    livenessType: "trueId", //"facetec" | "trueId";

    imageConfirmation: true,

    maxRetry: 10,

    themeConfig: {
      buttonTextColor: "#EEF2F5",
      stepBarColor: "#1182E7",
      stepBarBackgroundColor: "#E4F4FF",
      borderRadius: 8,
      defaultSpacing: 20,
      backgroundColor: "#ffffff",
      borderColor: "#efe8df",
      errorColor: "#e87722",
      primaryColor: "#ed1c24",
      buttonColor: "#ed1c24",
      secondaryColor: "#efe8df",
      textColor: "#231f20",
    },

    assetsConfig: {
      closeIcon: "../assets/trueidsdk/assets/close.svg",
      errorIcon: "../assets/trueidsdk/assets/error.svg",
      loadingIcon: "../assets/trueidsdk/assets/loading.svg",
    },

    languageConfig: {
      language: "vi",
      // trueIDLanguageJSON: "{}",
      // factecLanguageJSON: "{}"
    },

    // Determine SDK can close or not when `onSecurityCheck` trigger
    disableSecurityCheck: true,

    action: {
      onSuccess(userInfo) {
        console.log("Result", userInfo);
        onSuccess(userInfo);
      },
      onClose() {
        console.log("SDK Close");
      },
      onError(error: Error) {
        console.log("Error", error);
      },
      onSecurityCheck({ code, message }: { code: any; message: string }) {
        console.log("onSecurityCheck", { message, code });
      },
    },
  };

  const onSuccess = async (scanData) => {
    if (!!scanData) {
      const backCardImage = scanData.raw_image.back;
      const frontCardImage = scanData.raw_image.front;
      const selfie = scanData.raw_image.selfie;
      const front = scanData.ekyc_result.kyc_result.front;
      const decision = scanData.ekyc_result.kyc_result.decision;
      const complete = scanData.ekyc_result.kyc_result.complete;
      const person = scanData.id_info.data_fields_edited;

      const listCountry = await apiMain.getCountry();
      const country = listCountry.data.find((a: any) => a.id == "234");
      const listProvince = await apiMain.getProvince({
        countryId: 234,
      });
      const province = listProvince.data.find(
        (a: any) => a.administrativeCode == front.id_address_province?.code
      );
      const listDistrict = await apiMain.getDistrict({
        provinceId: province.id,
      });

      const district = listDistrict.data.find(
        (a: any) => a.administrativeCode == front.id_address_district?.code
      );
      const listWard = await apiMain.getWard({
        districtId: district.id,
      });
      const ward = listWard.data.find(
        (a: any) => a.administrativeCode == front.id_address_ward?.code
      );
      const userProfile = {
        gender: person?.gender == "MALE" ? 1 : 0,
        dob: convertStringTime(person?.dob || ""),
        nationalityId: 234,
        idTypeId: 1,
        idNo: person?.id_number,
        dateOfIssue: convertStringTime(person?.given_date),
        placeOfIssue: person?.given_place,
      };

      const userAddress = {
        permanentAddress: front?.id_address.value,
        countryId: 234,
        provinceId: province?.id || 0,
        districtId: district?.id || 0,
        wardId: ward?.id || 0,
        mailingAddress: front?.id_address.value || "",
        mailingCountryId: 234,
        mailingProvinceId: province?.id || 0,
        mailingDistrictId: district?.id || 0,
        mailingWardId: ward?.id || 0,
        country,
        province,
        district,
        ward,
      };
      const obj = {
        userProfile,
        userAddress,
        isKYC: decision?.code === 1,
        name: person?.name,
        person,
        backCardImage,
        frontCardImage,
        selfie,
        scanData,
      };
      if (scanData?.cardInfo?.result?.kyc_result?.decision?.code == -1) {
        Alert.showError({
          content: `alert.ekycfail`,
          onPress: () => {
            if (countTime.current >= 3) {
              navigate("LoginScreen");
              return;
            }
            gotoEKYC();
            countTime.current += 1;
          },
        });
        return;
      }
      const idNo = await getStoreData("idNo");
      const phone = await getStoreData("phone");
      if (!!idNo)
        if (
          (!!idNo && idNo != userProfile?.idNo) ||
          (!!phone && phone != route.params?.data?.phone)
        ) {
          Alert.showError({
            content: `Số giấy tờ tùy thân đăng ký tại Ngân hàng đang khác với số giấy tờ tùy thân sử dụng đăng ký mở tài khoản. Quý khách vui lòng kiểm tra lại`,
            multilanguage: false,
            type: 2,
            onClose: () => {
              PostMSG("CreateAccountFailure", {});
            },
            onPress: () => {
              PostMSG("CreateAccountFailure", {});
            },
            onCancel: () => {
              PostMSG("CreateAccountFailure", {});
            },
            onConfirm: () => {
              PostMSG("CreateAccountFailure", {});
            },
          });
          return;
        }

      navigate("ReviewInfoModal", {
        data: {
          ...obj,
          currentUser: {
            ...currentUser,
            ...(route.params?.data?.phone && {
              phone: route.params?.data?.phone,
            }),
            ...(route.params?.data?.password && {
              password: route.params?.data?.password,
            }),
            ...(route.params?.data?.name && {
              name: route.params?.data?.name,
            }),
            ...(route.params?.data?.email && {
              email: route.params?.data?.email,
            }),
          },
        },
      });
      return;
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (route.params?.data) {
  //       getAccountToken();
  //     }
  //   }, 300);
  //   return () => {};
  // }, [route.params?.data]);

  useEffect(() => {
    importL();
    return () => {};
  }, []);

  const importL = () => {
    setLoading(true);
    setTimeout(() => {
      try {
        import("../assets/trueidsdk/chunk/9371.chunk.js");
        import("../assets/trueidsdk/chunk/7068.chunk.js");
        import("../assets/trueidsdk/chunk/2511.chunk.js");
        import("../assets/trueidsdk/chunk/6267.chunk.js");
        import("../assets/trueidsdk/chunk/6425.chunk.js");
        import("../assets/trueidsdk/chunk/9938.chunk.js");
        import("../assets/trueidsdk/chunk/4835.chunk.js");
        import("../assets/trueidsdk/chunk/7535.chunk.js");
        import("../assets/trueidsdk/chunk/154.chunk.js");
        import("../assets/trueidsdk/chunk/8142.chunk.js");
        import("../assets/trueidsdk/chunk/2066.chunk.js");

        //

        import("../assets/trueidsdk/trueID.min.js")
          .then((r) => {
            Log("success");
            // trueID.init(config);
          })
          .catch((err) => {
            Log("errerrerrerr", err);
          });
      } catch (error) {
        importL();
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  // const getAccountToken = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await apiAuth.login({
  //       username: route.params?.data?.phone,
  //       password: route.params?.data?.password,
  //     });
  //     if (res.status == 200) {
  //       await setStoreToken(res.data.access_token);
  //       await setStoreData("mToken", res.data.access_token);
  //     }
  //   } catch (error) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const gotoEKYC = async () => {
    setLoading(true);
    try {
      const res = await apiAuth.login({
        username: route.params?.data?.phone,
        password: route.params?.data?.password,
        // username: `0987123111`,
        // password: "Kien12",
      });
      if (res.status == 200) {
        await setStoreToken(res.data.access_token);
        await setStoreData("mToken", res.data.access_token);
        trueID
          .init({
            ...config,
            apisConfig: {
              ...apisConfig,
              headerConfigJSON: JSON.stringify({
                authorization: `${res.data.access_token}`,
                clientid: "ea8df9ebff8d38479058d7f1d235e097",
              }),
            },
          })
          .then((result) => {
            if (!!result) {
              trueID.launch();
            }
          });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Div screen={true}>
      <HeaderBack
        isShowStep={true}
        step={3}
        type={4}
        onGoBack={() => {
          navigate("LoginScreen");
        }}
        title={`setpasswordscreen.titletrong`}
      />
      <Div paddingHorizontal={29}>
        <Div width={"100%"} alignItems={"center"}>
          <Label
            marginTop={30}
            textAlign={"center"}
            size={20}
            fontWeight={"700"}
          >
            {`controlekyc.xacthucthongtin`}
          </Label>
          <Label marginTop={6} textAlign={"center"} size={14} lineHeight={20}>
            {`controlekyc.content`}
          </Label>
        </Div>
        <Label marginTop={40} size={14} lineHeight={20}>
          {`controlekyc.chuphinhmattruoc`}
        </Label>
        <Div
          flexDirection={"row"}
          marginTop={17}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <ImageView
            width={132}
            height={100}
            resizeMode={"contain"}
            source={Icons.before}
            marginRight={11}
          />
          <ImageView
            width={132}
            height={100}
            resizeMode={"contain"}
            source={Icons.after}
          />
        </Div>
        <Label marginTop={18} size={14} lineHeight={20}>
          {`controlekyc.chuphinhchandung`}
        </Label>
        <Div>
          <ImageView
            height={100}
            marginTop={17}
            width={132}
            source={Icons.cmnd}
            resizeMode={"contain"}
          />
        </Div>
      </Div>
      <Div alignItems={"center"}>
        <ButtonBorder
          loading={loading}
          onPress={() => {
            gotoEKYC();
          }}
          marginTop={30}
          type={1}
          title={"controlekyc.batdau"}
        />
      </Div>
      <View nativeID={"trueID"} />
    </Div>
  );
  return (
    <Div screen={true}>
      <HeaderBack type={4} title={`Đăng nhập`} />

      <Div paddingHorizontal={29}>
        <Label marginTop={30} size={20} fontWeight={"700"}>
          {`Xác thực thông tin`}
        </Label>
        <Label marginTop={6} size={14} lineHeight={20}>
          {`Để hoàn tất việc mở tài khoản trên FPlatform, vui lòng thực hiện các bước xác thực thông tin dưới đây`}
        </Label>
        <Label marginTop={40} size={14} lineHeight={20}>
          {`Chụp hình CMND/CCCD`}
        </Label>
        <Div
          flexDirection={"row"}
          marginTop={17}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <ImageView
            width={132}
            height={90}
            resizeMode={"contain"}
            source={Icons.before}
            marginRight={11}
          />
          <ImageView
            resizeMode={"contain"}
            height={90}
            width={132}
            source={Icons.after}
          />
        </Div>

        <Label marginTop={18} size={14} lineHeight={20}>
          {`Chụp hình chân dung`}
        </Label>
        <Div>
          <ImageView
            marginTop={17}
            width={132}
            height={90}
            source={Icons.cmnd}
            resizeMode={"contain"}
          />
        </Div>
      </Div>
      <Div marginTop={20} alignItems={"center"}>
        <View nativeID={"trueidsdk"} />
        <ButtonBorder
          loading={loading}
          onPress={async () => {
            // const r = await doGetAxios(`user/investment/load`);
            // Log("rrr", r);
            // return;
            // Log(hashStringMd5(`1521572420377588986RR0377588986`));
            // Log("123123");
            // 'idNo: 152157242' \
            // --header 'Phone: 0377588986' \
            // --header 'refCode: RR0377588986' \
            var event = new CustomEvent("start", {
              detail: {
                config: {
                  domain: "https://api.fma.dev.techland.link/web",
                  domainPath: "/ekyc/v1.2",
                  authDomain: "https://sdk-stag.trueid.ai/auth",
                  authDomainPath: "/v1/oauth",
                  appId: "7c55d934f47f19371220aaa38b5d2a4c",
                  appSecret: "dIuYznA6cfoDixqHolokUOq5f32gW0XXSsHTVWKBMvk=",
                  language: "vi",
                  accessToken:
                    "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJpc3NhY2VAZmluY29ycC5jb20iLCJhdWRpZW5jZSI6IndlYiIsImNyZWF0ZWQiOjE2MzgzNDc0MjU3MzQsInVzZXJ0eXBlIjoiSU5WRVNUT1IiLCJleHAiOjE2MzgzNzYyMjV9.BqM34QpiWUIkVheBD0RMYCioDWPOZzcDCbM_l83fgzC8zZshq61Ey2eWCUWgs8D9licxk_Tn4dmWS9zEhVLyvA",
                },
              },
            });
            document.dispatchEvent(event);
          }}
          marginTop={30}
          type={1}
          title={"Bắt đầu"}
        />
      </Div>
    </Div>
  );
}

export default React.memo(ControlEKYCScreen);
